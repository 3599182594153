import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Banner = styled.div`
  padding: 130px 30px;
  background-position: 50% 0%;
  background-size: cover;
  background-repeat: no-repeat;
  @media (max-width: 1440px) {
    padding-top: 90px;
    padding-bottom: 90px;
  }
  @media (max-width: 991px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  @media(max-width: ${props => props.theme.breakpoints.max.md}){
    padding-top: 40px;
    padding-bottom: 40px;
  }
  @media(max-width: ${props => props.theme.breakpoints.max.sm}){
    background-position: 75% 0%;
    padding-top: 220px;
  }  
`;

const BannerContent = styled.div`
  position: relative;
  max-width: 1403px;
  margin: auto;
  padding: 60px 60px 40px;
  color: #ffffff;

  @media(max-width: ${props => props.theme.breakpoints.max.lg}){
    padding: 60px 40px 40px;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.sm}){
    padding: 60px 0px 40px;
  }  
`;

const BannerTitle = styled.h1`
  font-family: ${props => props.theme.font.nimbleFont};
  color: #ffffff;
  font-size: 50px;
  line-height: 60px;
  font-weight: 600;
  margin-bottom: 20px;
  font-style: normal;
  text-transform: unset;

  @media(max-width: ${props => props.theme.breakpoints.max.md}){
    font-size: 35px;
    line-height: 45px;
  }
`;

const Badge = styled.div`
  display: inline-block;
  padding: 19px 26px;
  transition: 0.35s ease;
  border-radius: 10px;
  color: #ffffff;
  background: ${props => props.theme.colours.red};
`;

const Button = styled.a`
  display: inline-block;
  cursor: pointer;
  padding: 19px 26px;
  transition: 0.35s ease;
  border-radius: 10px;
  color: #ffffff;
  background: ${props => props.theme.colours.darkGreen};

  @media(hover:hover) {
    &:hover {
      background: ${props => props.theme.colours.slate};
      color: #ffffff;    
    }
  }
`;

const ScholarshipBanner = ({ bannerData }) => {
  const {
    title,
    subTitle,
    desktopBackgroundImage,
    applyNowLink
  } = bannerData;

  return (
    <Banner style={{ backgroundImage: `url(${desktopBackgroundImage.fluid.src})` }}>
      <BannerContent>
        <BannerTitle>
          {title}
          <br />
          {subTitle}
        </BannerTitle>
        {applyNowLink === 'now-closed-no-link' ? (<Badge>Now Closed</Badge>) : null}
        {applyNowLink && applyNowLink !== 'now-closed-no-link' ? (<Button href={applyNowLink}>Apply Now</Button>) : null}
      </BannerContent>
    </Banner>
  );
};
ScholarshipBanner.propTypes = {
  bannerData: PropTypes.shape({
    title: PropTypes.string,
    subTitle: PropTypes.string,
    applyNowLink: PropTypes.string,
    desktopBackgroundImage: PropTypes.shape({
      fluid: PropTypes.shape({
        src: PropTypes.string
      })
    })
  })
};
export default ScholarshipBanner;
