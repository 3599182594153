import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import RichText from '../../../../elements/richText';
import checkImage from '../../../../../static/images/scholarship/check.png';

const Container = styled.div`
  padding: 120px 0;

  @media(max-width: ${props => props.theme.breakpoints.max.lg}){
    padding: 90px 0 30px;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.sm}){
    padding: 60px 0 0;
  }  
`;

const Content = styled.div`
  max-width: 1403px;
  margin: auto;
  padding-left: 60px;
  padding-right: 60px;

  @media(max-width: ${props => props.theme.breakpoints.max.md}){
    padding-left: 40px;
    padding-right: 40px;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.sm}){
    padding-left: 20px;
    padding-right: 20px;
  } 
`;

const Heading = styled.h2`
  text-align: center;
`;

const Description = styled.div`
  text-align: center;
  margin-bottom: 60px;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: -15px;
  margin-right: -15px;

  @media(max-width: ${props => props.theme.breakpoints.max.lg}){
    display: block;
    margin-left: 0px;
    margin-right: 0px;
  }
`;

const BlockContent = styled.div`
  border: 1px solid #e9e9e9;
  border-radius: 3px;
  box-sizing: border-box;
  height: 100%;
`;

const Block = styled.div`
  margin-bottom: 60px;
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;

  @media(max-width: ${props => props.theme.breakpoints.max.lg}){
    padding-left: 0;
    padding-right: 0;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.sm}){
    margin-bottom: 20px;
  } 

  ${props => props.cols === 3 && css`
    flex: 0 0 33%;
    text-align: center;

    ${BlockContent} {
      padding: 25px;

      @media(max-width: ${props.theme.breakpoints.max.xs}){
        padding: 20px;
      } 

      P {
        font-size: 14px;
      }
    }
  `}

  ${props => props.cols === 2 && css`
    flex: 0 0 50%;

    ${BlockContent} {
      padding: 45px;

      @media(max-width: ${props.theme.breakpoints.max.sm}){
        padding: 30px;
      }

      @media(max-width: ${props.theme.breakpoints.max.xs}){
        padding: 20px;
      } 
    }
  `}
`;

const BlockTitle = styled.h3`
  margin-bottom: 15px;
`;

const BlockImage = styled.img`
  margin-bottom: 30px;
  max-width: 58px;
`;

const BlockText = styled.div`
  & > ul {
    list-style: none;
    & > li {
      font-size: 18px;
      margin-bottom: 10px;
      margin-left: 0;
      padding-left: 45px;
      background-image: url(${checkImage});
      background-position: 15px 9px;
      background-repeat: no-repeat;
    }       
  }
`;

const Blocks = ({ sectionData, cols }) => {
  const {
    title,
    descriptionRichText,
    components
  } = sectionData;

  return (
    <Container>
      <Content>
        {title && <Heading>{title}</Heading>}
        {descriptionRichText && (
          <Description><RichText text={descriptionRichText} /></Description>
        )}
        <Row>
          {
            components.map((block) => {
              return (
                <Block key={block.basicHeading} cols={cols}>
                  <BlockContent>
                    {block.floatedImage && <BlockImage src={block.floatedImage.file.url} alt="" />}
                    {block.basicHeading && <BlockTitle>{block.basicHeading}</BlockTitle>}
                    {block.text && (
                      <BlockText><RichText text={block.text} /></BlockText>
                    )}
                  </BlockContent>
                </Block>
              );
            })
          }
        </Row>
      </Content>
    </Container>
  );
};

Blocks.propTypes = {
  sectionData: PropTypes.shape({
    title: PropTypes.string,
    descriptionRichText: PropTypes.shape({
      childContentfulRichText: PropTypes.shape({
        html: PropTypes.string
      })
    }),
    components: PropTypes.arrayOf(PropTypes.object)
  }),
  cols: PropTypes.number
};

export default Blocks;
