/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import styled from 'styled-components';
import ScholarshipBanner from './components/banner/scholarshipBanner.component';
import TextAndImage from './components/textAndImage/textAndImage.component';
import EligibilityPoints from './components/eligibilityPoints/eligibilityPoints.component';
import Blocks from './components/blocks/blocks.component';
import ScholarshipForm from './components/form/scholarshipForm.component';
import BannerCTA from './components/bannerCTA/bannerCTA.component';
import Terms from './components/termsAndConditions/termsAndConditions.component';

const ScholarshipPage = styled.div`
  h2 {
    font-family: ${props => props.theme.font.nimbleFont};
    color: ${props => props.theme.colours.slate};
    font-size: 40px;
    line-height: 55px;
    font-weight: 600;
    font-style: normal;

    @media(max-width: ${props => props.theme.breakpoints.max.md}){
      font-size: 30px;
      line-height: 35px;
    }
  }

  h3 {
    font-family: ${props => props.theme.font.nimbleBoldFont};
    color: ${props => props.theme.colours.slate};
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 30px;
  }

  h5 {
    font-family: ${props => props.theme.font.nimbleBoldFont};
    color: ${props => props.theme.colours.slate};
    padding: 0px;
    line-height: 1.6;
  }

  p {
    margin-top: 0px;
  }
`;

const Scholarship = ({
  componentData
}) => {
  const headingSectionData = componentData.filter(
    data => data.__typename === 'ContentfulNimblePageHeadingSection'
  );
  const basicData = componentData.filter(
    data => data.__typename === 'ContentfulComponentBasic'
  );
  const eligiblityPointsData = componentData.filter(
    data => data.sectionId === 'Scholarship - Eligibility points'
  );
  const criteriaData = componentData.filter(
    data => data.sectionId === 'Scholarship - Submission Criteria'
  );
  const infoData = componentData.filter(
    data => data.sectionId === 'Scholarship - Important Info'
  );
  const formData = componentData.filter(
    data => data.sectionId === 'Scholarship - Form'
  );
  const bannerData = componentData.filter(
    data => data.componentId === 'Scholarship - Banner'
  );
  const termsData = componentData.filter(
    data => data.sectionId === 'Scholarship - Terms and Conditions'
  );

  const [termsOpen, setTermsOpen] = useState(false);

  return (
    <ScholarshipPage>
      <ScholarshipBanner bannerData={headingSectionData[0]} />
      <TextAndImage basicData={basicData[0]} />
      <TextAndImage basicData={basicData[1]} />
      {eligiblityPointsData.length && (
        <EligibilityPoints sectionData={eligiblityPointsData[0]} />
      )}
      {criteriaData.length && (
        <Blocks sectionData={criteriaData[0]} cols={2} />
      )}
      {formData.length && (
        <ScholarshipForm formData={formData[0]} termsOpen={termsOpen} setTermsOpen={setTermsOpen} />
      )}
      {infoData.length && (
        <Blocks sectionData={infoData[0]} cols={3} />
      )}
      <BannerCTA sectionData={bannerData[0]} />
      <TextAndImage basicData={basicData[2]} />
      <Terms sectionData={termsData[0]} termsOpen={termsOpen} setTermsOpen={setTermsOpen} />
    </ScholarshipPage>
  );
};

export default Scholarship;
