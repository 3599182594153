import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Banner = styled.div`
  padding-right: 20px;
  padding-left: 20px;
  background-position: 50% 0%;
  background-size: cover;
  padding: 120px 0 60px;

  @media(max-width: ${props => props.theme.breakpoints.max.lg}){
    padding: 90px 0 70px;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.sm}){
    padding: 60px 0 40px;
  }
`;

const Content = styled.div`
  max-width: 1403px;
  margin: auto;
  padding-left: 60px;
  padding-right: 60px;

  @media(max-width: ${props => props.theme.breakpoints.max.md}){
    padding-left: 40px;
    padding-right: 40px;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.sm}){
    padding-left: 20px;
    padding-right: 20px;
  } 
`;

const Heading = styled.h2`
  text-align: center;
  color: #ffffff !important;
`;

const Description = styled.p`
  text-align: center;
  margin-bottom: 40px;
  color: #ffffff;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-left: -15px;
  margin-right: -15px;
`;

const ButtonContainer = styled.div`
  padding: 20px 15px;
`;

const Badge = styled.div`
  display: inline-block;
  padding: 19px 26px;
  transition: 0.35s ease;
  border-radius: 10px;
  color: ${props => props.theme.colours.red};
  background: #ffffff;
`;

const Button = styled.a`
  display: inline-block;
  cursor: pointer;
  padding: 19px 26px;
  transition: 0.35s ease;
  border-radius: 10px;
  color: #ffffff;
  background: ${props => props.theme.colours.darkGreen};

  @media(hover:hover) {
    &:hover {
      background: ${props => props.theme.colours.slate};
      color: #ffffff;    
    }
  }
`;

const BannerCTA = ({ sectionData }) => {
  const {
    bannerHeading,
    subheading,
    bannerImage,
    buttons
  } = sectionData;

  return (
    <Banner style={{ backgroundImage: `url(${bannerImage.file.url})` }}>
      <Content>
        <Heading dangerouslySetInnerHTML={{ __html: bannerHeading }} />
        <Description>{subheading}</Description>
        <Row>
          {
            buttons.map((button) => {
              return (
                <ButtonContainer key={button.linkText}>
                  {button.linkUrl === 'now-closed-no-link' ? (<Badge>{button.linkText}</Badge>) : null}
                  {button.linkUrl && button.linkUrl !== 'now-closed-no-link' ? (<Button href={button.linkUrl}>{button.linkText}</Button>) : null}
                </ButtonContainer>
              );
            })
          }
        </Row>
      </Content>
    </Banner>
  );
};

BannerCTA.propTypes = {
  sectionData: PropTypes.shape({
    bannerHeading: PropTypes.string,
    subheading: PropTypes.string,
    bannerImage: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string
      })
    }),
    buttons: PropTypes.arrayOf(PropTypes.object)
  })
};

export default BannerCTA;
