import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import RichText from '../../../../elements/richText';

const Container = styled.div`
  background: #f7f8f9;
  padding-bottom: 60px;
`;

const Content = styled.div`
  max-width: 1403px;
  margin: auto;
  padding-left: 60px;
  padding-right: 60px;

  @media(max-width: ${props => props.theme.breakpoints.max.lg}){
    padding-left: 40px;
    padding-right: 40px;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.sm}){
    padding-left: 20px;
    padding-right: 20px;
  } 
`;

const Heading = styled.h3`
  font-size: 18px !important;
  text-align: center;
`;

const Row = styled.div`
  margin-top: 60px;
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media(max-width: ${props => props.theme.breakpoints.max.lg}){
    display: block;
  }
`;

const Column = styled.div`
  flex: 0 0 30%;
  flex: 0 0 calc(33% - 20px);
  margin-bottom: 60px;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;

  @media(max-width: ${props => props.theme.breakpoints.max.lg}){
    padding-left: 20px;
    padding-right: 20px;
  } 

  @media(max-width: ${props => props.theme.breakpoints.max.sm}){
    padding-left: 0;
    padding-right: 0;
  } 
`;

const ColumnNumber = styled.p`
  line-height: 1;
  margin-bottom: 20px;
  font-family: ${props => props.theme.font.nimbleBoldFont};
  color: ${props => props.theme.colours.red};
  font-size: 34px;
  font-weight: 600;
  text-align: center;
`;

const ColumnText = styled.div`
  & > p {
    font-size: 14px;
    @media(max-width: ${props => props.theme.breakpoints.max.lg}){
      font-size: 16px;
    }
  }
  ol {
    list-style: lower-alpha;

    li{
      margin-left: 20px;
      padding-left: 10px;
      margin-bottom: 5px;
      
      &::marker {  
        font-family: ${props => props.theme.font.nimbleBoldFont};
      }
    }
  }
`;

const OrBorder = styled.div`
  flex: 0 0 20px;
  margin-bottom: 60px;
`;

const OrBorderText = styled.div`
  font-size: 14px;
  line-height: 18px;
  color: #929fb0;
  font-weight: 300;
  font-style: italic;
  text-transform: uppercase;
  display: block;
  margin: 0 0 33px 0;
  @media(max-width: ${props => props.theme.breakpoints.max.lg}){
    text-align: center;
    margin: 0;
    position: relative;

    &::before, &::after {
      content: '';
      position: absolute;
      width: 44%;
      height: 2px;
      top: 48%;
      background-color:#e2e8ff;
    }

    &::before {
      left: 0px;
    }

    &::after {
      right: 0px;  
    }
  }
`;

const OrBorderLine = styled.span`
  border-left: 2px solid #e2e8ff;
  display: block;
  height: 90%;
  margin: 0 0 0 9px;
  @media(max-width: ${props => props.theme.breakpoints.max.lg}){
    display: none;
  }
`;

const Disclaimer = styled.div`
  color: #929fb0;
  font-size: 13px;
  line-height: 1.4;
  text-align: center;
`;

const EligibilityPoints = ({ sectionData }) => {
  const {
    title,
    descriptionRichText,
    components
  } = sectionData;

  return (
    <Container>
      <Content>
        <Heading>{title}</Heading>
        <Row>
          {
            components.map((point, index, { length }) => {
              return (
                <React.Fragment key={point.text.raw}>
                  {index !== 0 && (
                    <OrBorder>
                      <OrBorderText>or</OrBorderText>
                      <OrBorderLine />
                    </OrBorder>
                  )}
                  <Column>
                    <ColumnNumber>
                      {index + 1}
                      .
                    </ColumnNumber>
                    <ColumnText><RichText text={point.text} /></ColumnText>
                  </Column>
                  {length > 3 && index + 1 === length && <OrBorder />}
                </React.Fragment>
              );
            })
          }
        </Row>
        <Disclaimer><RichText text={descriptionRichText} /></Disclaimer>
      </Content>
    </Container>
  );
};

EligibilityPoints.propTypes = {
  sectionData: PropTypes.shape({
    title: PropTypes.string,
    descriptionRichText: PropTypes.shape({
      childContentfulRichText: PropTypes.shape({
        html: PropTypes.string
      })
    }),
    components: PropTypes.arrayOf(PropTypes.object)
  })
};

export default EligibilityPoints;
