import React, { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import backgroundImage from '../../../../../static/images/scholarship/orange-bg.png';
import pdfIcon from '../../../../../static/images/scholarship/pdf-icon.png';
import arrowIcon from '../../../../../static/images/scholarship/arrow-down.png';
import { baseInput } from '../../../../elements/input/styles';

const FormWrapper = styled.div`
  padding-top: 270px;
  background-image: url(${backgroundImage});
  background-position: 50% 0%;
  background-size: 100% auto;
  background-repeat: no-repeat;

  @media(max-width: 1600px) {
    background-position: 55% 0%;
    background-size: auto 1000px;
  }

  @media (max-width: 1440px) {
    padding-top: 250px;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.lg}) {
    padding-top: 200px;
    background-position: 50% 0%;
    background-size: auto 900px;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.sm}) {
    padding-top: 170px;
    background-size: auto 800px;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.xs}) {
    padding-top: 80px;
    background-size: auto 550px;
  }
`;

const FormContainer = styled.div`
  max-width: 1403px;
  margin: auto;
  padding: 60px 60px 0;

  @media(max-width: ${props => props.theme.breakpoints.max.md}){
    padding-left: 40px;
    padding-right: 40px;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.sm}){
    padding-left: 20px;
    padding-right: 20px;
  } 
`;

const FormHead = styled.div`
  margin-bottom: 60px;

  @media(max-width: ${props => props.theme.breakpoints.max.md}) {
    margin-bottom: 30px;
  }
`;

const FormTitle = styled.h2`
  color: #ffffff !important;
  margin-bottom: 30px;
  text-align: center;

  @media(max-width: ${props => props.theme.breakpoints.max.md}) {
    margin-bottom: 15px;
  }
`;

const FormSubtitle = styled.p`
  margin-bottom: 0px;
  color: #ffffff;
  font-size: 18px;
  text-align: center;
`;

const SuccessMessage = styled.div`
  display: none;
  text-align: center;
  color: ${props => props.theme.colours.green};
  margin-top: 20px;
  margin-bottom: 20px;


  p {
    font-family: ${props => props.theme.font.nimbleBoldFont};
    font-size: 21px;
    line-height: 1.5;
  }
`;

const Submit = styled.button`
  display: block;
  text-align: center;
  font-size: 18px;
  margin: 50px auto 20px;
  max-width: 100%;
  width: 350px;
  color: #ffffff;
  background: ${props => props.theme.colours.green};
  border-radius: 10px;
  border: 0px;
  padding: 15px 30px;
  box-sizing: border-box;
  text-decoration: none;
  transition: all 0.35s ease;
  &:hover{
    background: ${props => props.theme.colours.slate};
    color: #ffffff;
    cursor: pointer;
  }
  &:focus {
    outline: dashed currentColor 1px;
  }
`;

const FormBackground = styled.div`
  max-width: 767px;
  margin: 0 auto;
  background: #ffffff;
  padding: 60px;
  border-radius: 3px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);

  @media(max-width: ${props => props.theme.breakpoints.max.lg}) {
    padding: 40px;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.sm}) {
    padding: 30px;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.xs}) {
    padding: 30px 20px 20px;
  } 

 
  &.apply-complete {
    ${Submit} {
      display: none;
    }

    ${SuccessMessage}{
      display: block;
    }
  }
`;

const FieldContainer = styled.div`
  margin-bottom: 30px;
  flex: 0 0 50%;
`;

const RowFieldContainer = styled.div`
  display: flex;

  @media(min-width: ${props => props.theme.breakpoints.min.md}) {
    ${FieldContainer}:first-child{
      padding-right: 15px;
    }

    ${FieldContainer}:last-child{
      padding-left: 15px;
    }
  }

  @media(max-width: ${props => props.theme.breakpoints.max.md}) {
    display: block;
  }
`;

const StyledLabel = styled.label`
  display: block;
  font-size: ${props => props.theme.size.large};
  line-height: 24px;
  margin-bottom: 8px;
`;

const StyledInput = styled.input`
  ${baseInput}
  &.error{
    border-color: red;
  }
`;

const Separator = styled.div`
  border-bottom: 1px solid #e9e9e9;
  margin-bottom: 30px;
`;

const UploadTitle = styled.p`
  font-size: ${props => props.theme.size.large};
  line-height: 24px;
  margin-bottom: 8px;
`;

const UploadInfo = styled.div`
  p {
    font-size: 14px;
    line-height: 20px;
    color: #6b7c93;
    margin: 0 0 15px 0;
  }
`;

const UploadInfoPoint = styled.span`
  display: block;
  margin-bottom: 2px;

  @media(max-width: ${props => props.theme.breakpoints.max.md}) {
    margin-bottom: 15px;
  }
`;

const UploadButtonContainer = styled.div`
  @media(max-width: ${props => props.theme.breakpoints.max.xs}) {
    flex: 100%;
    margin-top: 12px;
    margin-bottom: 5px;
  }
`;

const UploadFileSummary = styled.div`
  display: none;
  flex: 1;
  justify-content: flex-end;
  align-items: center; 

  @media(max-width: ${props => props.theme.breakpoints.max.md}) {
    flex: 100%;
    margin-top: 7px;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.xs}) {
    flex-wrap: wrap;
  }
`;

const UploadContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 20px 0;

  @media(max-width: ${props => props.theme.breakpoints.max.md}) {
    flex-wrap: wrap;
  }

  &.error {
    p {
      color: red;
      font-weight: bold;
    }
  }

  &.has-file {
    ${UploadButtonContainer} {
      display: none;
    }

    ${UploadFileSummary} {
      display: flex;
    }
  }
`;

const UploadLabel = styled.p`
  background: url(${pdfIcon}) no-repeat center left;
  font-size: 14px;
  line-height: 40px;
  padding: 0 0 0 40px;
  margin: 0;
`;

const UploadInput = styled.input`
  display: none;
`;

const UploadButtonLabel = styled.label`
  display: block;
  width: 180px;
  height: 42px;
  background: #ffffff;
  border: 1px solid ${props => props.theme.colours.green};
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  line-height: 42px;
  color: ${props => props.theme.colours.green};
  font-family: $nimbleFont;
  font-weight: 600;
  text-align: center;
  transition: 0.3s ease;
  margin: 0;

  &:hover {
    background: ${props => props.theme.colours.green};
    color: #ffffff;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.sm}) {
    width: auto;
    flex: 1;
    padding: 0 10px;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.xs}) {
    max-width: 100px;
  }
`;

const UploadFileName = styled.div`
  font-size: 14px;
  word-break: break-all;
  padding-left: 10px;
  box-sizing: border-box;
  color: ${props => props.theme.colours.green};

  @media(max-width: ${props => props.theme.breakpoints.max.xs}) {
    flex: 100%;
    margin-bottom: 12px;
    text-align: right;
  }
`;
const UploadFileSize = styled.div`
  font-size: 11px;
  padding-left: 10px;
  color: #888;

  &:after {
    content: "MB";
  }
`;
const UploadFileClear = styled.button`
  display: block;
  height: 42px;
  background: #ffffff;
  border: 1px solid ${props => props.theme.colours.slate};
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
  color: ${props => props.theme.colours.slate};
  font-weight: 600;
  text-align: center;
  transform-text: uppercase;
  transition: 0.3s ease;
  margin: 0;
  padding: 0 10px;
  margin-left: 10px;
  vertical-align: middle;

  &:hover {
    background: ${props => props.theme.colours.slate};
    color: #ffffff;
  }
`;

const UploadSizeExceeded = styled.div`
  display: none;
  color: red;
  padding: 0 0 20px 0;
  text-align: center;
  font-size: 15px;

  &.error {
    display: block;
  }
`;

const TermsToggle = styled.a`
  display: inline-block;
  padding-right: 35px !important;
  background-image: url(${arrowIcon});
  background-position: 100% 50%;
  background-repeat: no-repeat;
  color: #6785ff;
  transition: 0.35s ease;

  &:hover {
    text-decoration: none;
    color: ${props => props.theme.colours.green};
  }

  &:focus {
    background-image: url(${arrowIcon});
    background-position: 100% 50%;
    background-repeat: no-repeat;
  }
`;

const ScholarshipForm = ({ formData, termsOpen, setTermsOpen }) => {
  const {
    title,
    subtitle
  } = formData;

  const handleTermsToggle = () => {
    if (termsOpen === false) {
      setTermsOpen(true);
    }
  };

  // Form Functions -----------------------------
  // Form - Variables
  const fileNames = [];
  const fileSizesMb = [];
  let form = null;
  let uploadSizeExceededLabel = null;
  const fileInputNames = ['proof_of_identity_file', 'essay_file', 'statement_file', 'enrolment_file'];
  const maxTotalUploadsMb = 25.0;

  // Form - input remove error class and check validity
  const handleInputChange = (e) => {
    if (e.currentTarget.val !== null || e.currentTarget.val !== '') {
      e.currentTarget.classList.remove('error');
      e.currentTarget.checkValidity();
    }
  };

  // Form - add eventlistners to all inputs
  useEffect(() => {
    form = document.getElementById('scholarshipForm');
    uploadSizeExceededLabel = document.getElementById('schuploadSizeExceeded');
    const inputs = form.querySelectorAll('input, select, textarea');

    inputs.forEach((input) => {
      input.addEventListener('invalid', (e) => {
        e.currentTarget.classList.add('error');
      });
      input.addEventListener('blur', handleInputChange);
      input.addEventListener('change', handleInputChange);
      input.addEventListener('keyup', handleInputChange);
    });
  }, []);

  // Form - File - if has file display files details and make sure total max upload is not exceeded
  const updateFileInputs = () => {
    let fileSizeTotalMb = 0;
    fileInputNames.forEach(fieldName => {
      const fileName = fileNames[fieldName];
      const fileSizeMb = fileSizesMb[fieldName] || 0;
      const hasFile = !!fileName;
      const fileInput = document.getElementById(fieldName);
      const uploadHolder = fileInput.closest('.sch-upload-container');
      if (hasFile) {
        uploadHolder.classList.add('has-file');
        uploadHolder.classList.remove('error');
      } else {
        uploadHolder.classList.remove('has-file');
      }
      const fileNameLabel = uploadHolder.querySelector('.sch-upload-file-name');
      fileNameLabel.innerHTML = fileName;
      const fileSizeLabel = uploadHolder.querySelector('.sch-upload-file-size');
      fileSizeLabel.innerHTML = fileSizeMb;
      fileSizeTotalMb += fileSizeMb;
    });

    document.getElementById('schuploadTotalSize').innerHTML = `${fileSizeTotalMb} MB`;
    if (fileSizeTotalMb > maxTotalUploadsMb) {
      uploadSizeExceededLabel.classList.add('error');
    } else {
      uploadSizeExceededLabel.classList.remove('error');
    }
  };

  // Form - File - add new file to fileNames and FileSizesMb
  const handleFile = (e) => {
    const fileInput = e.currentTarget;
    const fieldName = fileInput.getAttribute('name');
    if (fileInput.files && !!fileInput.files.length) {
      const fileName = fileInput.files[0].name;
      fileNames[fieldName] = fileName;
      // MB at least 0.1
      fileSizesMb[fieldName] = Math.max(0.1, ((fileInput.files[0].size / 1024) / 1024).toFixed(1));
    } else {
      fileNames[fieldName] = null;
      fileSizesMb[fieldName] = 0;
    }

    updateFileInputs();
  };

  // Form - File - remove uploaded file
  const handleFileClear = (e) => {
    const removeButton = e.currentTarget;
    const fileInput = removeButton.closest('.sch-upload-container').querySelector('input[type="file"]');
    fileInput.value = '';
    const fieldName = fileInput.getAttribute('name');
    fileNames[fieldName] = null;
    fileSizesMb[fieldName] = 0;
    updateFileInputs();
  };

  // Form - File - make sure total max upload is not exceeded and all files meet valid standards
  const validateFileInputs = () => {
    let valid = true;
    let fileSizeTotalMb = 0;
    fileInputNames.forEach(fieldName => {
      const fileName = fileNames[fieldName];
      const fileSizeMb = fileSizesMb[fieldName] || 0;
      const hasFile = !!fileName;
      const fileInput = document.getElementById(fieldName);
      const uploadHolder = fileInput.closest('.sch-upload-container');
      if (hasFile) {
        uploadHolder.classList.remove('error');
      } else {
        uploadHolder.classList.add('error');
      }
      valid = valid && hasFile;
      fileSizeTotalMb += fileSizeMb;
    });

    if (fileSizeTotalMb > maxTotalUploadsMb) {
      valid = false;
      uploadSizeExceededLabel.classList.add('error');
    } else {
      uploadSizeExceededLabel.classList.remove('error');
    }

    return valid;
  };

  // Form - Submission (all validations passed)
  const handleFormSubmission = () => {
    const formSubmissionData = new FormData(form);

    fetch('https://getform.io/f/65a7cf3d-b624-4370-ae3e-71696bd9b017', {
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json'
      },
      body: formSubmissionData
    })
      .then(() => {
        document.getElementById('formHolder').classList.add('apply-complete');
      })
      .catch(() => {
        alert('Form submission failed');
      });
  };

  // Form - Submit button pressed
  const handleFormSubmit = (e, calledFrom) => {
    if (calledFrom === 'form') {
      e.preventDefault();
    }

    // validate uploads
    const valid = validateFileInputs();

    if (!valid) {
      setTimeout(() => {
        const errorPosition = form.querySelector('.error').offsetTop;
        window.scrollTo({
          top: errorPosition - 50,
          behavior: 'smooth'
        });
      }, 50);
    }

    if (calledFrom === 'form' && valid) {
      handleFormSubmission();
    }
  };

  return (
    <FormWrapper>
      <FormContainer>
        <FormHead>
          <FormTitle>{title}</FormTitle>
          {subtitle && <FormSubtitle>{subtitle}</FormSubtitle>}
        </FormHead>
        <FormBackground id="formHolder">
          <form encType="multipart/form-data" method="POST" id="scholarshipForm" onSubmit={(e) => handleFormSubmit(e, 'form')}>
            <FieldContainer>
              <StyledLabel htmlFor="schfirstname">Full name (inc. given names):*</StyledLabel>
              <StyledInput id="schfirstname" name="full_name" type="text" required placeholder="Your full name" />
            </FieldContainer>
            <FieldContainer>
              <StyledLabel htmlFor="schaddress">Address*</StyledLabel>
              <StyledInput id="schaddress" name="address" type="text" required placeholder="Your address" />
            </FieldContainer>
            <RowFieldContainer>
              <FieldContainer>
                <StyledLabel htmlFor="schemail">Email*</StyledLabel>
                <StyledInput id="schemail" name="email" type="email" pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$" required placeholder="eg. johndoe@mail.com" />
              </FieldContainer>
              <FieldContainer>
                <StyledLabel htmlFor="schphone">Phone number*</StyledLabel>
                <StyledInput id="schphone" name="mobile" type="text" required placeholder="eg. +61 xxx xxx xxx" />
              </FieldContainer>
            </RowFieldContainer>
            <Separator />
            <div>
              <UploadTitle>Proof of identity*</UploadTitle>
              <UploadContainer className="sch-upload-container">
                <UploadLabel>Upload proof of identity:</UploadLabel>
                <UploadButtonContainer>
                  <UploadInput type="file" name="proof_of_identity_file" id="proof_of_identity_file" onChange={handleFile} />
                  <UploadButtonLabel htmlFor="proof_of_identity_file">UPLOAD</UploadButtonLabel>
                </UploadButtonContainer>
                <UploadFileSummary>
                  <UploadFileName className="sch-upload-file-name" />
                  <UploadFileSize className="sch-upload-file-size" />
                  <UploadFileClear type="button" title="Remove file and upload another" onClick={handleFileClear}>Clear</UploadFileClear>
                </UploadFileSummary>
              </UploadContainer>
              <UploadInfo>
                <p>Proof of identity can be in the form of:</p>
                <p>
                  <UploadInfoPoint>
                    A photocopy/photograph of the applicant&apos;s driver&apos;s license card
                    <strong> OR </strong>
                  </UploadInfoPoint>
                  <UploadInfoPoint>
                    A photocopy/photograph of the applicant&apos;s passport photo page
                    <strong> OR </strong>
                  </UploadInfoPoint>
                  <UploadInfoPoint>
                    A photocopy/photograph of the applicant&apos;s state-issued photo ID card
                    <strong> OR </strong>
                  </UploadInfoPoint>
                  <UploadInfoPoint>
                    A photocopy/photograph of the applicant&apos;s student ID card and a utilities
                    bill with the applicant&apos;s full name and current address showing
                  </UploadInfoPoint>
                </p>
              </UploadInfo>
            </div>
            <Separator />
            <FieldContainer>
              <StyledLabel htmlFor="schaddress">Name of education institution*</StyledLabel>
              <StyledInput id="schaddress" name="education_inst" type="text" required />
            </FieldContainer>
            <FieldContainer>
              <StyledLabel htmlFor="schaddress">Name of degree being studied (in full)*</StyledLabel>
              <StyledInput id="schaddress" name="degree" type="text" required />
            </FieldContainer>
            <Separator />
            <FieldContainer>
              <StyledLabel htmlFor="schaddress">Degree to be completed in:*</StyledLabel>
              <StyledInput id="schaddress" name="degreecompletedin" type="text" required />
            </FieldContainer>
            <div className="degrees">
              <UploadContainer className="sch-upload-container">
                <UploadLabel>Written essay:</UploadLabel>
                <UploadButtonContainer>
                  <UploadInput type="file" name="essay_file" id="essay_file" onChange={handleFile} />
                  <UploadButtonLabel htmlFor="essay_file">UPLOAD</UploadButtonLabel>
                </UploadButtonContainer>
                <UploadFileSummary>
                  <UploadFileName className="sch-upload-file-name" />
                  <UploadFileSize className="sch-upload-file-size" />
                  <UploadFileClear type="button" title="Remove file and upload another" onClick={handleFileClear}>Clear</UploadFileClear>
                </UploadFileSummary>
              </UploadContainer>
              <UploadContainer className="sch-upload-container">
                <UploadLabel>Short statement:</UploadLabel>
                <UploadButtonContainer>
                  <UploadInput type="file" name="statement_file" id="statement_file" onChange={handleFile} />
                  <UploadButtonLabel htmlFor="statement_file">UPLOAD</UploadButtonLabel>
                </UploadButtonContainer>
                <UploadFileSummary>
                  <UploadFileName className="sch-upload-file-name" />
                  <UploadFileSize className="sch-upload-file-size" />
                  <UploadFileClear type="button" title="Remove file and upload another" onClick={handleFileClear}>Clear</UploadFileClear>
                </UploadFileSummary>
              </UploadContainer>
              <UploadContainer className="sch-upload-container">
                <UploadLabel>Proof of enrolment:</UploadLabel>
                <UploadButtonContainer>
                  <UploadInput type="file" name="enrolment_file" id="enrolment_file" onChange={handleFile} />
                  <UploadButtonLabel htmlFor="enrolment_file">UPLOAD</UploadButtonLabel>
                </UploadButtonContainer>
                <UploadFileSummary>
                  <UploadFileName className="sch-upload-file-name" />
                  <UploadFileSize className="sch-upload-file-size" />
                  <UploadFileClear type="button" title="Remove file and upload another" onClick={handleFileClear}>Clear</UploadFileClear>
                </UploadFileSummary>
              </UploadContainer>
            </div>
            <Separator />
            <div className="center">Total upload size can not exceed 25MB</div>
            <UploadSizeExceeded id="schuploadSizeExceeded">
              Selected file uploads are currently&nbsp;
              <span id="schuploadTotalSize" />
              &nbsp;which exceeds the 25MB limit.
            </UploadSizeExceeded>
            <Submit type="submit" onClick={(e) => handleFormSubmit(e, 'button')}>Applications now closed</Submit>
            <SuccessMessage><p>Your scholarship application has been sent.</p></SuccessMessage>
          </form>
          <div className="center">
            <p>
              For Terms and Conditions,&nbsp;
              <TermsToggle href="#terms-holder" onClick={handleTermsToggle}>click here</TermsToggle>
            </p>
          </div>
        </FormBackground>
      </FormContainer>
    </FormWrapper>

  );
};
ScholarshipForm.propTypes = {
  formData: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    applyNowLink: PropTypes.string,
    desktopBackgroundImage: PropTypes.shape({
      fluid: PropTypes.shape({
        src: PropTypes.string
      })
    })
  }),
  termsOpen: PropTypes.bool,
  setTermsOpen: PropTypes.func
};
export default ScholarshipForm;
